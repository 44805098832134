

////////////////////////////
// COLORS
////////////////////////////
$primary-color: #212b5c !important;

$theme-colors: (
  "primary": $primary-color,
);

$theme-colors: (
  "custom-color": #900
);

////////////////////////////
// END COLORS
////////////////////////////

@font-face {
  font-family:"Avenir Next";
  src: url("/fonts/AvenirNext-Medium.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family:"Avenir Next";
  src: url("/fonts/AvenirNext-Regular.ttf") format("truetype");
  font-weight: 200;
}


@font-face {
  font-family: "Avenir";
  src: url("/fonts/Avenir-Medium.ttf") format("truetype");
}

@font-face {
  font-family:"Avenir";
  src: url("/fonts/AvenirNext-Regular.ttf") format("truetype");
  font-weight: 200;
}