@import "./theme";

.cursor-pointer {
  cursor: pointer !important;
}

.action-blocked {
  display: none;
}

body.modal-open,
body.block-fullscreen {
  .fade.fade-right.in {
    transform: initial;
  }
}

.modal {
  z-index: 40000;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.modal-backdrop.in {
  opacity: 0.6;
}

@media (min-width: 992px) {
  .modal-lg {
    width: 90%;
  }
}

.css-yk16xz-control {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  -webkit-box-shadow: 0 1px 0 #e6e6e6 !important;
  box-shadow: 0 1px 0 #e6e6e6 !important;
}

.jobs .css-yk16xz-control {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-color: hsl(0,0%,80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  background-color: hsl(0,0%,100%) !important;
  box-shadow: 0 1px 0 #e6e6e6 !important;
}

.css-1wa3eu0-placeholder {
  color: hsl(0, 0%, 67%) !important;
}

.css-g1d714-ValueContainer {
  padding: 0px 2px !important;
}

.css-w8afj7-Input {
  margin-left: -8px !important;
}

.css-1pahdxg-control:hover,
.css-1pahdxg-control:focus,
.css-1pahdxg-control:active {
  background-color: transparent !important;
  box-shadow: 0 2px 0 #646464 !important;
  border: 0 !important;
}

.jobs .css-1pahdxg-control:hover,
.jobs .css-1pahdxg-control:focus,
.jobs .css-1pahdxg-control:active {
  background-color: hsl(0,0%,100%) !important;
  box-shadow: 0 1px 0 #e6e6e6 !important;
  border-color: hsl(0,0%,80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
}

@media screen and (min-width: 768px) {
  .content.content-boxed {
    max-width: 1500px !important;
  }
}

.block.block-themed.block-opt-refresh-icon7 {
  .table-scrolling {
    height: 300px !important;
    overflow-y: scroll !important;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  &.block-opt-fullscreen {
    .table-scrolling {
      height: 73vh !important;
    }
  }
}

//.block.block-opt-fullscreen {
//  top: 60px !important;
//  left: 60px !important;
//  z-index: 1024 !important;
//}

.bg-image {
  background-color: #3e4a58 !important;
  background-blend-mode: luminosity !important;
}

.content.bg-image.overflow-hidden {
  background-color: #3e4a58 !important;
  background-image: url("https://image.freepik.com/vector-gratis/diseno-fondo-lineas-sobre-fondo-azul_1308-4823.jpg") !important;
  background-blend-mode: luminosity !important;
}

.checkbox-row {
  width: 20px;

  .css-checkbox {
    margin: 0px;
  }
}

.row-active {
  background-color: #f6fdff !important;
}

//////////////////////////////////////////////////
// CONTEXT MENU
//////////////////////////////////////////////////

.contextMenu {
  position: fixed;
  background: white;
  box-shadow: 0px 2px 20px #999999;
  z-index: 3;
  text-align: left;

  &--option {
    padding: 6px 50px 5px 10px;
    min-width: 160px;
    cursor: default;
    font-size: 12px;

    &:hover {
      background: linear-gradient(to top, #555, #333);
      color: white;
    }

    &:active {
      color: #e9e9e9;
      background: linear-gradient(to top, #555, #444);
    }

    &__disabled {
      color: #999999;
      pointer-events: none;
    }
  }

  &--separator {
    width: 100%;
    height: 1px;
    background: #cccccc;
    margin: 0 0 0 0;
  }
}

//////////////////////////////////////////////////
// END CONTEXT MENU
//////////////////////////////////////////////////

#main-container {
  background-color: #f8f8ff;
}

.accounting-table {
  margin: 0px 24px;
  //width: 80%;
}

.accounting-entry-list {
  list-style-type: none;
  padding: 0px;
}

.accounting-entry {
  background-color: white;
  display: flex;
  margin: 8px 0px;
  border-radius: 10px;
  padding: 8px 24px;
  box-shadow: 1px 1px 1px 1px #f3f4f5;
  cursor: pointer;
  align-items: center;

  .accounting-entry-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 8px;
    .field-title {
      font-weight: bold;
      color: #1f2b5c;
      margin-bottom: 4px;
      font-family: Avenir, sans-serif;
    }
  }

  .field-value {
    //color: rgb(170, 170, 188);
    color: #646464;
    font-weight: 200;
    font-size: 1.24rem;
    font-family: "Avenir Next", sans-serif;
  }

  .entry-status {
    font-family: "Avenir Next", sans-serif;
    .pending-status {
      background-color: #dfe1ff;
      color: #313a6d;
    }
    .failed-status {
      background-color: #e91e63;
      color: #ffffff;
    }
  }
}

.accounting-table-headers {
  display: flex;
  flex: 1;

  .accounting-entry-list {
    display: flex;
    flex-direction: column;
    > li {
      display: flex;
      flex: 1;
      width: 240px;
      font-weight: bold;
      color: #1f2b5c;
      font-size: 16px;
    }
    ul {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
    }
  }
}

.accounting-container {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  flex: 1;
}

.accounting-table {
  display: flex;
  flex-direction: column;
}

.accounting-details {
  display: flex;
  //width: 300px;
}

.accounting-title {
  padding: 0px 48px;
  h1 {
    font-family: Avenir;
    color: #1f2b5c;
    font-weight: bold;
  }
}

.flex-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.flex-row-filter {
  display: flex;
  justify-content: flex-end;
  margin: 1rem auto 3rem auto;
}

.accounting-filters {
  flex: 1;
  //height: 120px;
  background-color: rgba(255, 255, 255, 0.85);
  // margin: 16px 48px;
  padding-left: 0px !important;
}

// Auth styles
.fixed-extent-80 {
  width: 80px;
}

.fixed-extent-40 {
  width: 40px;
}

.fixed-extent-160 {
  width: 160px;
}

.text-centered {
  text-align: center;
}

.accounting-filters {
  //align-items: center;
  justify-content: center;
  font-family: Avenir;
  color: #1f2b5c;
  font-weight: bold;
}

.accounting-action-btn {
  text-transform: capitalize;
  padding: 8px 16px;
  border: 1px dashed #ff2e73;
  border-radius: 15px;
  color: #ff2e73;
  font-family: Avenir Next;
  font-weight: 500;

  &:hover {
    background-color: #ff2e73;
    color: white;
    //border: 2px solid white;
    cursor: pointer;
  }
}

body {
  font-family: Avenir Next;
}

.header-logo {
  display: flex;
  img {
    width: 96px;
    //height: 40px;
  }
}

.nav-buttons {
  .btn {
    border: none;
    background: none;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  //width:100%;
  //height: 100%;
  background: rgba(0, 0, 0, 0.55);
}

.modal-main {
  position: fixed;
  background: white;
  //width: 80%;
  border-radius: 5px;
  height: auto;
  max-height: 80vh;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-menu-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  list-style: none;
  padding-left: 32px;
  margin: 0px;

  a {
    margin: 0px;
    color: #1f2b5c;
    font-size: 1.35rem;
    font-family: Avenir Next;
    font-weight: 300;
    .fa {
      margin-left: 4px;
    }
  }
}

.dropdown-menu {
  li {
    padding: 8px 16px;
    &:hover {
      background-color: #f8f9fa;
    }
  }
  ul {
    list-style: none;
    padding: 0px;
  }
  .dropdown-item {
    display: flex;
    flex: 1;
    align-items: center;
    color: $primary-color;
    font-family: "Avenir";
    line-height: 36px;
    i {
      margin-right: 8px;
    }
  }
}

.accounting-filters {
  border-radius: 10px;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  padding-bottom: 0px !important;
  .filter {
    padding: 8px 16px;
  }
}

.select-filter {
  min-width: 200px;
  z-index: 3;
  //max-width: 350px ;
}

.filter-label {
  font-size: 14px;
  font-family: Avenir;
  margin-bottom: .6rem;
}

.css-1wa3eu0-placeholder {
  font-weight: 200;
}

.jobs .css-1wa3eu0-placeholder {
  margin-left: 8px !important;
}

.css-12jo7m5 {
  font-weight: 200;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.jobs .css-1okebmr-indicatorSeparator {
  width: 1px !important;
}

.css-yk16xz-control {
  box-shadow: none !important;
}

.shortcut {
  background: #e7edff;
  margin-right: 12px;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  text-transform: capitalize;
  border: none;
  color: #1f2b5c;
}

.badge {
  margin-left: 8px;
  background-color: #ff2e73;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 400;
}

.flex-container {
  margin: 8px 24px;
}

// Spinner

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #673ab7;
  }
  25% {
    stroke: #673ab7;
  }
  50% {
    stroke: #673ab7;
  }
  75% {
    stroke: #673ab7;
  }
  100% {
    stroke: #673ab7;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.loader {
  display: flex;
  flex: 1;
  //height: 60vh;
  align-items: center;
  justify-content: center;
}

.loading-message {
  h4 {
    font-size: 16px;
    color: #1f2b5c;
    font-weight: 500;
    font-family: Avenir Next;
    margin: 16px 0px;
  }
}

.header-navbar-fixed #header-navbar {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
  min-height: 0px;
  padding: 8px 16px;
}

.modal-component {
  width: 400px;
  padding: 48px;
}

.modal-close-button {
  float: right;
  background: none;
  border: none;
  font-size: 16px;
  color: black;
  margin: 24px;
}

.modal-title {
  font-size: 18px;
  color: #1f2b5c;
  font-weight: 600;
  margin: 16px 0px;
}

.modal-description {
  margin-top: 18px;
  margin-bottom: 32px;
}

.modal-action {
  float: right;
  background: #1f2b5c;
  border-color: #1f2b5c;
  margin-bottom: 16px;
}

.flexbox {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.empty-placeholder {
  display: flex;
  flex: 1;
  min-height: 70vh;
  justify-content: center;
  align-items: center;
  img {
    object-fit: scale-down;
    height: 300px;
  }
}

.mg-top-40 {
  margin-top: 40px;
}

.user-tile {
  //max-width: 500px;
  padding: 8px 16px;
  margin: 4px 8px;
}
.user_email {
  font-size: 11px;
  font-weight: 200;
}

.role-label {
  font-size: 10px;
  padding: 2px 8px;
  background-color: rgb(238, 240, 241);
  border-radius: 10px !important;
  text-align: center;
  margin-top: 2px;
  margin-right: 4px;
}

.form-control .flexbox {
  /* height: 42px; */
  /* margin-top: 32px; */
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 400px;
  border: none !important;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  font-size: 1.2rem;
  font-weight: 200;
}

// Non reconciled items styles
.unrec-source-card {
  width: 180px;
  height: 180px;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-right: 16px;
  .flag-icon {
    margin: 0 0;
    width: 46px !important;
    height: 46px !important;
  }
  .source-name {
    color: $primary-color;
  }
  &:hover {
    cursor: pointer;
    border: 0.15px solid $primary-color;
  }
}

.wizard-title {
  margin: 24px 0;
  font-size: 22px;
  font-weight: bold;
  color: $primary-color;
}

.config-card {
  margin: 8px 32px;
  background: white;
  padding: 8px;
  width: 180px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.config-title {
  font-family: Avenir;
  color: rgb(31, 43, 92);
  padding: 0px;
  margin-top: 2rem;
  font-size: 22px;
}

.page-title {
  padding-left: 16px;
  margin-bottom: 32px;
  margin-top: 16px;
  h5 {
    margin-top: 8px;
    font-size: 1.3rem;
    font-family: Avenir;
    font-weight: 200;
  }
}

.config-table {
  thead {
    tr > th {
      font-family: "Avenir Next";
      color: #1f2b5c !important;
      text-transform: none;
      text-align: center;
      border-bottom: none;
    }
  }
  tbody {
    tr > td {
      border-top: none;
      line-height: 36px;
      font-weight: 200;
      select {
        text-align: center;
        text-align-last: center;
        border: none;
        background: #f8f9fa;
        font-weight: 200;
      }
      .btn-danger {
        border-color: #ff2e73;
        background: transparent;
        color: #ff2e73;
      }
    }
  }
}

/* Spinner css */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $primary-color;
  border-color: #fff $primary-color #fff $primary-color;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.DateRangePickerInput__withBorder {
  border: none !important;
}
.DateInput_input {
  font-size: 16px;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px !important;
  height: 38px !important;
  box-shadow: 0 1px 0 #e6e6e6 !important;
}
// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: #f5f6f7; //background
  color: #484848; //text
  border: none; //default styles include a border
  &:hover {
    background-color: darken(#f5f6f7, 30%);
    border: none;
  }
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: #f5f6f7;
  //color: white;
  border: none;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: #f6f7f8;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #f5f6f7;
  color: #0b2e13;
  border: none;
}

.DateInput_input__focused {
  border-bottom: 2px solid $primary-color;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background: $primary-color;
  color: white;
  border: none;
}

.simetrik_input {
  min-width: 250px;
  font-weight: 200;
  font-family: Avenir Next;
  font-size: 14px;
  padding: 16px 16px;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px !important;
  height: 38px !important;
  box-shadow: 0 1px 0 #e6e6e6 !important;
}

.success-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 32px;
    margin-right: 16px;
    height: 32px;
  }
}

.deny-btn {
  color: #202020;
  background-color: rgb(214, 214, 214);
  border: none !important;
  margin-left: 8px;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: 300;
  &:hover {
    background-color: darken(rgba(240, 240, 240, 1), 0.5);
    color: darken(rgb(134, 134, 134), 0.5);
  }
}

.deny-btn-light {
  background-color: rgba(240, 240, 240, 1);
  color: rgb(134, 134, 134);
  padding: 8px 32px;
  cursor: default !important;
}
.positive-btn {
  background-color: #ff2e73;
  color: white;
  border: none !important;
  margin-left: 8px;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: 300;
  &:hover {
    background-color: darken(#ff2e73, 0.2);
  }
}
.navigate-btn {
  border: 1px solid #1f2b5c;
  background: #1f2b5c;
  color: #f8f8ff !important;
  border-radius: 5%;
}

.deploy-tool {
  .client-selector {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 24px;
    h3 {
      padding-top: 24px;
      font-size: 2.25rem;
      font-weight: bold;
      color: black;
      font-family: "Avenir Next";
    }
  }

  .client-list {
    margin: 24px;
    margin-top: 64px;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 5rem;
  }
  .client-card {
    margin: 12px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    width: 180px;
    border: 3px dashed #1f2b5c;
    color: black;
    font-size: 1.5rem;
    border-radius: 5%;
    &:hover {
      background-color: #1f2b5c;
      color: white;
      cursor: pointer;
    }
  }

  .block-content {
    display: flex;
    flex: 1;
    margin: 48px !important;
    padding: 24px !important;
    min-height: 80vh;
  }
}

.creator-button {
  border: 2px dashed #1f2b5c !important;
  background: white;
  color: black;
  padding: 14px 8px;
  margin-bottom: 24px;
  cursor: pointer;
  margin-left: auto;

  & button {
    background-color: transparent;
    border: none;
    color: #5c90d2;
    font-family: inherit;

    &:hover {
      color: #3169b1;
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: flex-end;

  div {
    margin: 10px 0 40px 10px;
  }
}

.block-header {
  border-radius: 5%;
  h3 {
    text-transform: none !important;
  }
}

.deploy-tool-loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.deploy-tool-loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fcf;
  border-color: #fcf transparent #fcf transparent;
  animation: deploy-tool-loader 1.2s linear infinite;
}
@keyframes deploy-tool-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.deploy-tool-creator {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 80vh;
  background: white;
  margin: 24px;
  padding: 16px;

  .title {
    font-weight: bold;
    color: black;
    font-size: 2.25rem;
    margin: 8px 0px;
    p {
      font-size: 1.6rem;
      color: #646464;
      font-weight: 300;
    }
  }
}

.deploy-tool-wizard {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.react-tabs {
  border-color: #1f2b5c !important;
}

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f4f4f4;
  margin: 1rem;
  border-radius: 1%;
}

.accordion-title:hover {
  background-color: #1f2b5c;
  color: white;
  border-radius: 1%;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: transparent;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}

.database-change {
  display: flex;
  padding: 8px;
  background-color: #fefefe;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 8px;
}

.change-content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.change-badge {
  display: inline-flex;
  padding: 4px;
  background-color: #f0f0f0;
  color: black;
  margin: 0px 4px;
  border-radius: 5%;
  max-width: 300px;
  text-overflow: ellipsis;
  max-lines: 1;
  max-height: 30px;
  .value {
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 4px;
    font-weight: 300;
    color: #646464;
  }
}

.counter-badge {
  margin: 0px 16px !important;
  padding: 4px 4px !important;
  background: #ff2e73 !important;
  color: white !important;
  line-height: 1.5rem !important;
  border-radius: 50% !important;
  width: 24px !important;
  text-align: center !important;
}

.icon-warning {
  background-color: #FFC43A;
  border-radius: 15%;
  margin: 0px 10px;
  i {
    color: #FFFFFF
  }

  &:hover {
    background-color: #e05959;
    border-radius: 15%;
  }
}

.release-code-input {
  border: none;
  background: #f5f5f5;
  padding: 8px 12px;
  border-radius: 5%;
  margin-left: 16px;
}

.release-inputs {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  justify-content: space-between;
  align-items: center;
  label {
    display: flex;
    flex: 1;
    align-items: center;
  }
  textarea {
    margin-left: 16px;
    border: none;
    background: #f5f5f5;
    padding: 8px 12px;
    border-radius: 5%;
    display: flex;
    flex: 1;
  }
}

.deploy-creator-submit {
  border: none;
  padding: 8px 16px;
  background: #1f2b5c;
  color: white;
  float: right;
  margin: 3rem 1.5rem;
  cursor: pointer;
}

.release-error-message {
  color: red;
  font-size: 1.15rem;
  font-weight: 300;
}

.release-status {
  display: flex;
  padding: 8px 16px;
  background-color: #ffeb3b;
  display: flex;
  width: 180px;
  border-radius: 5%;
  font-size: 1.15rem;
  color: black;
  align-items: center;
  justify-content: center;
  &.success {
    background-color: #4caf50;
    color: white;
  }
  &.error {
    background-color: #f44336;
    color: white;
  }
  &.alternate {
    background-color: #00bcd4;
    color: white;
  }
}

.bg-primary-dark {
  background-color: #1f2b5c;
}

.release-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.disabled {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: #ccc;
}

.preview-query {
  cursor: pointer;
  margin-right: 10px;
}

.swal2-popup {
  font-size: 1.4rem !important;
}

.block-content .jobs {
  padding: 1.9rem 0 3rem 0;
  .filter-select {
    margin-top: 1rem;
  }
}