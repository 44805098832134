.m-top {
  margin-top: 20px;
  padding: 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.m-bottom {
  margin-bottom: 20px;
  padding: 0 20px;
  text-align: center;
  color: #ef2c53;
}

.modal-main {
  position: relative impr !important;
  padding: 10px;

  .btn-container {
    display: flex;
    justify-content: space-evenly;
    color: white;

    .btn {
      padding: 7px 12px;
      border-color: white;
      border-radius: 5px;
      transition: all 0.3s ease;

      &:hover {
        cursor: pointer;
        color: white;
        transform: scale(1.09);
      }
    }

    .cancel {
      background-color: #ef2c53;
    }

    .success {
      background-color: #1f2b5c;
    }
  }
}
